import icon from '@plone/volto/icons/right-key.svg';
import Edit from './Edit';
import View from './View';

export const questionsBlock = {
  id: 'questionsBlock',
  title: 'Questions Block',
  edit: Edit,
  view: View,
  icon: icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
