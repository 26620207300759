import { ContentFooter } from './ContentFooter';
import { FlandersFooter } from './FlandersFooter';
import { SupportFooter } from './SupportFooter';
import { useGoogleAnalytics } from 'volto-google-analytics';
import { useCookies } from 'react-cookie';

export const Footer = () => {
  const [cookies] = useCookies();
  let google_track = false;
  if (!!Number(cookies[`confirm_tracking`])) {
    google_track = true;
  }
  useGoogleAnalytics(google_track);

  return (
    <footer id="footer">
      <SupportFooter />
      <ContentFooter />
      <FlandersFooter />
    </footer>
  );
};
