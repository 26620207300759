import { GraphBlockEdit } from './Edit';
import icon from '@plone/volto/icons/right-key.svg';
import { GraphBlockView } from './View';

export const graphBlock = {
  id: 'graphBlock',
  title: 'Grafiek',
  edit: GraphBlockEdit,
  view: GraphBlockView,
  icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
