import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import View from './View';
import type { BlockEditProps } from '@plone/types';

const schema = {
  title: 'Questions Block',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'text', 'name', 'email', 'phone', 'openingHours'],
    },
  ],
  properties: {
    title: {
      id: 'title',
      title: 'Titel',
      placeholder: 'Type hier de titel',
    },
    text: {
      id: 'text',
      title: 'Tekst',
      placeholder: 'Type hier de tekst',
    },
    name: {
      id: 'name',
      title: 'Name',
      placeholder: 'Type hier de naam',
    },
    email: {
      id: 'email',
      title: 'Email',
      placeholder: 'Type hier het emailadres',
    },
    phone: {
      id: 'phone',
      title: 'Phone',
      placeholder: 'Type hier het telefoonnummer',
    },
    openingHours: {
      id: 'openingHours',
      title: 'Openingstijden',
      placeholder: 'Type hier wat de openingstijden zijn',
    },
  },
  required: ['title', 'text', 'name', 'email', 'phone', 'openingHours'],
};

const Edit = (props: BlockEditProps) => {
  const { onChangeBlock, block, data, selected, blocksErrors } = props;

  return (
    <>
      <View {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};
export default Edit;
