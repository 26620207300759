import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Grid } from 'semantic-ui-react';
import { H3, Text } from '../../Typography';
import { Image, UniversalLink } from '@plone/volto/components';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Carousel } from '../../Carousel';

type ListingItem = RelatedItem;
export const ProjectListingTemplate = ({
  items,
  styles,
}: BlockViewProps & {
  items: ListingItem[];
  blocks_layout: { items: any[] };
  styles: { gridCutoff: 2 | 3 | 4 };
}) => {
  const isMobile = !useBreakpoint('md');

  const GridComponent = isMobile ? Carousel : Grid;

  return (
    <div className="vmm-listing__items">
      <GridComponent
        numberOfPages={items.length}
        stackable
        stretched
        columns={styles?.gridCutoff || 3}
      >
        {items.map((item) => (
          <Grid.Column key={item.UID} className="vmm-listing__item">
            <UniversalLink
              item={item}
              href={undefined}
              openLinkInNewTab={false}
            >
              <Image item={item} />
              <div className="vmm-listing__content">
                <H3 size="h3" weight="500">
                  {item.title}
                </H3>
                <Text size="sm">{item.description}</Text>
              </div>
            </UniversalLink>
          </Grid.Column>
        ))}
      </GridComponent>
    </div>
  );
};
