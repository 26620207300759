import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Tags } from '../../Tag/Tags';
import { H3, Text } from '../../Typography';

type ListingItem = RelatedItem & { tags: string[] };
type Props = BlockViewProps & {
  '@type': string;
  item: ListingItem;
  blocks_layout: { items: any[] };
  styles?: { gridCutoff: 2 | 3 | 4 };
  isEditMode: boolean;
};

const CatalogusItem = ({ item, isEditMode }: Props) => {
  return (
    <div className="listing-item listing-item--catalogus" key={item['@id']}>
      <ConditionalLink to={null} item={item} condition={!isEditMode}>
        <div className="listing-body">
          <H3>{item.title ? item.title : item['@id']}</H3>
          <Text>{item.description}</Text>
          <Tags tags={item.tags} />
        </div>
      </ConditionalLink>
    </div>
  );
};
CatalogusItem.propTypes = {
  item: PropTypes.any.isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default CatalogusItem;
