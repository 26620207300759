import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import type { BlockEditProps } from '@plone/types';
import type { RelatedItem } from '@plone/types/src/content/common';
import { GraphBlockView } from './View';

const schema = {
  title: 'Graph',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['target'],
    },
  ],

  properties: {
    target: {
      title: 'Content',
      widget: 'object_browser',
      mode: 'link',
      return: 'single',
      selectedItemAttrs: ['@type', 'image_scales'],
    },
  },

  required: ['target'],
};

export type GraphBlockData = {
  '@type': string;
  target: null | RelatedItem;
};

export const GraphBlockEdit = (
  props: BlockEditProps & {
    data: GraphBlockData;
  },
) => {
  const { selected, onChangeBlock, block, data, blocksErrors } = props;
  return (
    <>
      <GraphBlockView {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          title={schema.title}
          schema={schema}
          onChangeBlock={onChangeBlock}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => onChangeBlock(block, { ...data, [id]: value })}
          formData={data}
          block={block}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};
