import FACEBOOK from '../../assets/facebook.svg';
import LINKEDIN from '../../assets/linkedin.svg';
import TWITTER from '../../assets/twitter.svg';
import LINK from '../../assets/link.svg';
import Tag from '../Tag';
import { defineMessages, useIntl } from 'react-intl';
import { useState } from 'react';

type TagSocialProps = {
  lastModified?: string;
  effectiveDate?: string;
  showSocials?: boolean; // Updated to optional
  tags: Array<string>;
  isEditMode: boolean;
  contenttype?: string;
};

type ConditionalSocialLinkProps = {
  isEditMode: boolean;
  img: string;
  link: string;
  social: string;
};

// Define translatable messages
const messages = defineMessages({
  ShareOnline: {
    id: 'ShareOnline',
    defaultMessage: 'Share online', // Deel online
  },
  LastUpdated: {
    id: 'LastUpdated',
    defaultMessage: 'Last updated:', // Laatst bijgewerkt:
  },
  LinkCopied: {
    id: 'Link copied',
    defaultMessage: 'Link copied',
  },
});

const ConditionalSocialLink = ({
  isEditMode,
  img,
  link,
  social,
}: ConditionalSocialLinkProps) => {
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';

  return isEditMode ? (
    <img src={img} alt={social} />
  ) : (
    <a href={link + currentURL} target="_blank" rel="noopener noreferrer">
      <img src={img} alt={social} />
    </a>
  );
};

export const TagSocial = ({
  lastModified,
  effectiveDate,
  showSocials = true, // Set default value here
  tags,
  isEditMode,
  contenttype,
}: TagSocialProps) => {
  const intl = useIntl();
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';

  const [copied, setCopied] = useState(false);
  const copyUrl = () => {
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(currentURL);
      setCopied(true);
    }
  };

  return (
    <div className="tag-social">
      <div className="flex">
        <div className="left">
          {contenttype === 'News Item' && effectiveDate && (
            <p className="text">
              {Intl.DateTimeFormat('nl-NL', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              }).format(new Date(effectiveDate))}
            </p>
          )}
          <div className="tags">
            {tags && tags.map((tag, index) => <Tag label={tag} key={index} />)}
          </div>
        </div>
        {showSocials && (
          <div className="right">
            <p className="text">{intl.formatMessage(messages.ShareOnline)}</p>
            <div className="socials">
              <ConditionalSocialLink
                isEditMode={isEditMode}
                img={FACEBOOK}
                link="https://www.facebook.com/sharer/sharer.php?u="
                social="facebook"
              />
              <ConditionalSocialLink
                isEditMode={isEditMode}
                img={LINKEDIN}
                link="https://www.linkedin.com/shareArticle?url="
                social="LinkedIn"
              />
              <ConditionalSocialLink
                isEditMode={isEditMode}
                img={TWITTER}
                link="https://twitter.com/intent/tweet?url="
                social="Twitter"
              />

              <button type="button" onClick={copyUrl}>
                {!copied ? (
                  <img src={LINK} alt="link" />
                ) : (
                  intl.formatMessage(messages.LinkCopied)
                )}
              </button>
            </div>
          </div>
        )}
      </div>
      {contenttype === 'project' && lastModified && (
        <p className="text bottomDate">
          {`${intl.formatMessage(messages.LastUpdated)} ${Intl.DateTimeFormat(
            'nl-NL',
            {
              month: 'long',
              year: 'numeric',
            },
          ).format(new Date(lastModified))}`}
        </p>
      )}
    </div>
  );
};
