import PropTypes from 'prop-types';
import cx from 'classnames';
import { ComponentType, useState } from 'react';
import _ContainerEdit from '@plone/volto/components/manage/Blocks/Container/Edit';
import type { BlockEditProps } from '@plone/types';

const ContainerEdit: ComponentType<any> = _ContainerEdit;

type Data = {
  styles: { [key: string]: string };
  blocks_layout?: { items: any[] };
};

const GridBlockEdit = (props: BlockEditProps & { data: Data }) => {
  const { data } = props;
  const columnsLength = data.styles.gridCutoff as unknown as number;

  const [selectedBlock, setSelectedBlock] = useState(null);

  return (
    <div
      className={cx(
        {
          two: columnsLength === 2,
          three: columnsLength === 3,
          four: columnsLength >= 4,
          'grid-items': true,
        },
        Object.keys(data.styles || {})
          .map((k) => data.styles[k] && `has--${k}--${data.styles[k]}`)
          .filter(Boolean),
        props.className,
      )}
      // This is required to enabling a small "in-between" clickable area
      // for bringing the Grid sidebar alive once you have selected an inner block
      onClick={(e: React.MouseEvent<HTMLDivElement> & { block?: unknown }) => {
        if (!e.block) setSelectedBlock(null);
      }}
      role="presentation"
    >
      <ContainerEdit
        {...props}
        selectedBlock={selectedBlock}
        setSelectedBlock={setSelectedBlock}
        direction="horizontal"
      />
    </div>
  );
};

GridBlockEdit.propTypes = {
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  manage: PropTypes.bool.isRequired,
};

export default GridBlockEdit;
