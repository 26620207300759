import { Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '../../Button';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import { Icon } from '@plone/volto/components';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const SearchInput = (props: {
  data: {
    searchInputPrompt?: string;
  };
  searchText: string;
  setSearchText: (s: string) => void;
  removeSearchQuery: () => void;
  isLive: boolean;
  onTriggerSearch: (s: string) => void;
  id: string;
}) => {
  const { data, searchText, setSearchText, isLive, onTriggerSearch } = props;
  const intl = useIntl();

  return (
    <div className="search-input">
      <Icon className="search-input__icon" name={zoomSVG} size="24px" />
      <Input
        id={`${props.id}-searchtext`}
        value={searchText}
        placeholder={
          data.searchInputPrompt || intl.formatMessage(messages.search)
        }
        fluid
        onKeyPress={(event: KeyboardEvent) => {
          if (isLive || event.key === 'Enter') onTriggerSearch(searchText);
        }}
        onChange={(_, { value }) => {
          setSearchText(value);
          if (isLive) {
            onTriggerSearch(value);
          }
        }}
      />

      <div className="search-input-actions">
        {isLive && (
          <>
            <Button halfRounded size="input">
              {data.searchInputPrompt || intl.formatMessage(messages.search)}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
