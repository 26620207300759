import type { BlocksConfigData, BlockConfigBase } from '@plone/types';
import { addStyling } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { defineMessages } from 'react-intl';

export const defaultBackgroundColors = [
  { label: 'Transparant', name: 'transparent' },
  { label: 'Light', name: 'light' },
  { label: 'Dark', name: 'dark' },
];

export const defaultStylingSchema = ({
  schema,
  formData,
  intl,
}: {
  schema: any;
  formData: any;
  intl: any;
}) => {
  const messages = defineMessages({
    backgroundColor: {
      id: 'Background color',
      defaultMessage: 'Background color',
    },
  });
  const BG_COLORS = defaultBackgroundColors;

  const blockConfig = config.blocks?.blocksConfig?.[
    formData['@type'] as keyof BlocksConfigData
  ] as BlockConfigBase & {
    colors: typeof defaultBackgroundColors;
    defaultBGColor: string;
  };

  addStyling({ schema, intl, formData });

  schema.properties.styles.schema.fieldsets[0].fields = [
    ...schema.properties.styles.schema.fieldsets[0].fields,
    'backgroundColor',
  ];
  schema.properties.styles.schema.properties.backgroundColor = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.backgroundColor),
    colors: blockConfig?.colors || BG_COLORS,
    default: blockConfig?.defaultBGColor,
  };

  return schema;
};
