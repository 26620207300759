import Arrow from '../../../theme/components/arrow_right.svg';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  toOverview: {
    id: 'toOverview',
    defaultMessage: 'To the overview',
  },
});

type ServiceViewProps = {
  content: {
    blocks: any;
    title: string;
    description: string;
  };
};

const ServiceView = ({ content }: ServiceViewProps) => {
  const intl = useIntl();

  return (
    <div id="page-document" className="service">
      <a className="to-summary" href="/diensten-producten">
        <button>
          <img src={Arrow} alt="arrow" />
        </button>
        <p>{intl.formatMessage(messages.toOverview)}</p>
      </a>
      <div className="service-content">
        <div className="service-left">
          <RenderBlocks content={content} />
        </div>
        <div className="service-right"></div>
      </div>
    </div>
  );
};

export default ServiceView;
