import { BodyClass } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import errorSVG from '../../../../assets/page-not-found.svg';
import './NotFound.scss';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */

// Define translatable messages
const messages = defineMessages({
  notfoundTitle: {
    id: 'This page does not seem to exist',
    defaultMessage: 'This page does not seem to exist',
  },
  notfoundMessage: {
    id: 'If you are certain you have the correct web address but are encountering this error, please ',
    defaultMessage:
      'If you are certain you have the correct web address but are encountering this error, please ',
  },
  notfoundContact: {
    id: 'contact us',
    defaultMessage: 'contact us',
  },
  notfoundBackHomepage: {
    id: 'Back to homepage',
    defaultMessage: 'Back to homepage',
  },
});

const NotFound = () => {
  const intl = useIntl();

  return (
    <Container className="view-wrapper">
      <BodyClass className="page-not-found" />
      <img
        src={errorSVG}
        alt={intl.formatMessage(messages.notfoundTitle)}
        className="error-page-icon"
      />
      <div className="error-message-block">
        <h1 className="documentFirstHeading">
          {intl.formatMessage(messages.notfoundTitle)}
        </h1>
        <p>
          {intl.formatMessage(messages.notfoundMessage)}{' '}
          <Link to="/contact/contactformulier">
            {intl.formatMessage(messages.notfoundContact)}
            {'.'}
          </Link>
        </p>
        <button className="button button--medium button--tertiary button--border--tertiary">
          <Link to="/">
            {intl.formatMessage(messages.notfoundBackHomepage)}
          </Link>
        </button>
      </div>
    </Container>
  );
};

export default withServerErrorCode(404)(NotFound);
