import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { H3, Link, Text } from '../../Typography';
import type { RelatedItem } from '@plone/types/src/content/common';
import ICON from '../../../assets/arrow-transparent.svg';
import ICON_PRIMARY from '../../../assets/arrow-primary.svg';

type SpotlightTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  href:
    | undefined
    | { '@id': string; hasPreviewImage: boolean; image_field: string }[];
  relatedLinks: RelatedItem[];
};
type Content = {
  styles: {
    backgroundColor?: string;
  };
};
export const Spotlight = ({
  data,
  content,
  isEditMode,
}: BlockViewProps & { isEditMode: boolean; data: SpotlightTeaserData } & {
  content: Content;
}) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const relatedLinks = data.relatedLinks;
  const LinkComponent = isEditMode ? H3 : Link;
  const isDark = content.styles?.backgroundColor === 'dark';
  return (
    <div className="teaser spotlight-teaser">
      {href && (
        <>
          <div className="teaser-item default">
            {(href.hasPreviewImage || href.image_field || image) && (
              <div className="image-wrapper">
                <Image
                  item={image || href}
                  imageField={image ? image.image_field : href.image_field}
                  alt=""
                  loading="lazy"
                  responsive={true}
                />
              </div>
            )}
            <div className="teaser__content">
              <LinkComponent
                openLinkInNewTab={false}
                href={href['@id']}
                size="h3"
                className="headline"
              >
                {data.title}
                <img
                  alt="icon"
                  className={`teaser__headline-chevron ${!isDark ? 'teaser__headline-chevron--dark' : ''}`}
                  src={isDark ? ICON : ICON_PRIMARY}
                />
              </LinkComponent>
              {!data.hide_description && (
                <Text size="sm">{data?.description}</Text>
              )}
              {relatedLinks && (
                <div className="teaser__related-links">
                  {relatedLinks.map((item) => (
                    <Link
                      key={item.UID}
                      openLinkInNewTab={false}
                      href={undefined}
                      item={item}
                      size="sm"
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
