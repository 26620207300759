import { IndicatorHeaderEdit } from './Edit';
import { IndicatorHeader } from './DefaultIndicatorHeader';
import icon from '@plone/volto/icons/right-key.svg';
import { IndicatorHeaderView } from './View';
import { BlankIndicatorHeader } from './BlankIndicatorHeader';

export const indicatorHeader = {
  id: 'indicatorHeader',
  title: 'Indicator header',
  edit: IndicatorHeaderEdit,
  view: IndicatorHeaderView,
  variations: [
    {
      title: 'Standaard',
      id: 'default',
      template: IndicatorHeader,
      isDefault: true,
    },
    {
      title: 'Halve hoogte',
      id: 'small',
      template: (props: any) => <IndicatorHeader {...props} small />,
      schemaEnhancer: ({
        schema,
        intl,
        formData,
      }: {
        schema: any;
        intl: any;
        formData: any;
      }) => {
        const index = schema.fieldsets.findIndex(
          (f: { id: string }) => f.id === 'call-to-action',
        );
        schema.fieldsets.splice(index, 1);

        return schema;
      },
    },
    {
      title: 'Zonder afbeelding',
      id: 'blank',
      template: BlankIndicatorHeader,
      schemaEnhancer: ({ schema }: { schema: any }) => {
        schema.fieldsets = schema.fieldsets.filter(
          (_: unknown, i: number) => i === 0,
        );

        return schema;
      },
    },
  ],
  icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
