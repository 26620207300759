import React from 'react';
import config from '@plone/volto/registry';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';

const View = (props) => {
  const { data } = props;
  const intl = useSelector((state) => state.intl);

  return (
    <div className="block quote test">
      <div className="inner-wrapper">
        <blockquote
          className={data.plaintext?.length < 1 && 'isEmpty'}
          cite={data.cite}
          lang={
            data.quotationLanguage !== intl.locale && data.quotationLanguage
          }
        >
          <div className="block-quote-text">&quot;{data?.plaintext}&quot;</div>
          <footer className="block-quote-footer">
            {config.blocks?.blocksConfig?.quote?.showImageField &&
              data.image?.[0] && (
                <img
                  src={`${flattenToAppURL(data.image?.[0]?.['@id'])}/${
                    data.image?.[0]?.image_scales?.image[0]?.scales?.preview
                      ?.download
                  }}`}
                  alt={data.image?.[0]?.title}
                  className="image block-quote-image"
                  loading="lazy"
                />
              )}
            <div>
              <div className="block-quote-author">{data?.author}</div>
              <div className="block-quote-information">
                {data?.additional_information}
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  );
};

export default View;
