import { WarningLevelView } from './View';

import icon from '@plone/volto/icons/warning.svg';

export const warningLevelConfig = {
  id: 'warningLevel',
  title: 'Waarschuwingsniveau',
  view: WarningLevelView,
  edit: WarningLevelView,
  icon: icon,
  restricted: true,
  group: 'common',
  mostUsed: false,
  sideBarTab: 0,
};
