type TagProps =
  | {
      label: string;
      children?: undefined;
    }
  | { children: string; label?: undefined };

const Tag = ({ label, children }: TagProps) => {
  return (
    <div className="tag">{label?.split('>')?.pop()?.trim() || children}</div>
  );
};

export default Tag;
