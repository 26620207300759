import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { H3, Link, Text } from '../../Typography';
import { Box } from '../../Box';
import { Tags } from '../../Tag/Tags';

type ProjectTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  createdAt: string;
  href:
    | undefined
    | {
        '@id': string;
        hasPreviewImage: boolean;
        image_field: string;
        created: string;
        tags: string[];
      }[];
};
export const ProjectTeaser = ({
  data,
  isEditMode,
}: BlockViewProps & { data: ProjectTeaserData; isEditMode: boolean }) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const Wrapper = isEditMode ? Box : Link;

  return (
    <div className="teaser project-teaser">
      {href && (
        <Wrapper
          openLinkInNewTab={false}
          href={href['@id']}
          className="teaser-item default"
        >
          {(href.hasPreviewImage || href.image_field || image) && (
            <div className="image-wrapper">
              <Image
                item={image || href}
                imageField={image ? image.image_field : href.image_field}
                alt=""
                loading="lazy"
                responsive={true}
              />
            </div>
          )}
          <div className="teaser__content">
            {data?.head_title && <Text size="h2">{data.head_title}</Text>}

            <H3 size="h3" className="headline">
              {data?.title}
            </H3>
            {!data.hide_description && (
              <Text size="sm" weight="400">
                {data?.description}
              </Text>
            )}
            <div className="teaser__outline-spacer" />
            <Tags tags={href?.tags} />
          </div>
        </Wrapper>
      )}
    </div>
  );
};
