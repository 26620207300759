import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import PublicationTeaser from './PublicationTeaser';

type PublicationViewProps = {
  content: {
    blocks: any;
    title: string;
    description: string;
    text: {
      data: string;
    };
    image: {
      download: string;
    };
    image_caption: string;
    attachment: {
      download: string;
    };
    published: string;
  };
};

const PublicationView = ({ content }: PublicationViewProps) => {
  const publishedDate = Intl.DateTimeFormat('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(content.published));

  return (
    <div id="page-document" className="publication">
      <div className="publication-left">
        <RenderBlocks content={content} />
      </div>
      <div className="publication-right">
        <PublicationTeaser
          image={content?.image?.download}
          download={content?.attachment?.download}
          teaser_alt={content?.image_caption}
          published_date={publishedDate}
        />
      </div>
    </div>
  );
};

export default PublicationView;
