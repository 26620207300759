import { ReactNode, useEffect, useState } from 'react';
import {
  SearchDetails,
  Facets,
  FilterList,
  SortOn,
  ViewSwitcher,
} from '@plone/volto/components/manage/Blocks/Search/components';
import { Grid, Segment } from 'semantic-ui-react';
import { flushSync } from 'react-dom';
import cx from 'classnames';
import SearchInput from './SearchInput';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Button } from '../../Button';
import settingsSvg from '@plone/volto/icons/settings.svg';
import { Icon } from '@plone/volto/components';

const FacetWrapper = ({
  children,
  facetSettings = {},
  visible,
}: {
  children: ReactNode;
  facetSettings: { advanced?: any; field?: any };
  visible?: boolean;
}) => {
  const { advanced, field = {} } = facetSettings;

  return (
    <Segment
      basic
      className={cx('facet', {
        [`facet-index-${field.value}`]: !!field.value,
        'advanced-facet': advanced,
        'advanced-facet-hidden': !visible,
      })}
    >
      {children}
    </Segment>
  );
};

const LeftColumnFacets = (props: any) => {
  const {
    children,
    data,
    totalItems,
    facets,
    setFacets,
    setSortOn,
    setSortOrder,
    sortOn,
    sortOrder,
    onTriggerSearch,
    searchedText, // search text for previous search
    setSearchText,
    isEditMode,
    querystring = {},
    // searchData,
    // mode = 'view',
    // variation,
  } = props;
  const [showMobileFacets, setShowMobileFacets] = useState(false);
  const { showSearchButton } = data;
  const isLive = !showSearchButton;

  const isMobile = !useBreakpoint('md');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchString = params.get('SearchableText') || '';
    if (searchString) {
      setSearchText(searchString);
    }
  }, [setSearchText]);

  return (
    <Grid className="searchBlock-facets left-column-facets" stackable>
      {data.headline && (
        <Grid.Row>
          <Grid.Column>
            <h2 className="headline">{data.headline}</h2>
          </Grid.Column>
        </Grid.Row>
      )}

      <Grid.Row>
        {data.facets?.length > 0 && (
          <Grid.Column
            className="search__facet-column"
            mobile={12}
            tablet={4}
            computer={3}
          >
            <div className="facets">
              {data.facetsTitle && <h3>{data.facetsTitle}</h3>}

              {isMobile && (
                <Button
                  square
                  halfRounded
                  size="large"
                  color="border"
                  className={showMobileFacets ? 'active' : ''}
                  onClick={() => setShowMobileFacets((b) => !b)}
                >
                  <Icon name={settingsSvg} size="28px" />
                </Button>
              )}
              {data.showSearchInput && (
                <div className="search-wrapper">
                  <SearchInput {...props} isLive={isLive} />
                </div>
              )}

              {!isMobile && (
                <Facets
                  querystring={querystring}
                  data={data}
                  facets={facets}
                  setFacets={(f: any) => {
                    flushSync(() => {
                      setFacets(f);
                      onTriggerSearch(searchedText || '', f);
                    });
                  }}
                  facetWrapper={FacetWrapper}
                />
              )}
            </div>
            {isMobile && showMobileFacets && (
              <div className="facets">
                <Facets
                  querystring={querystring}
                  data={data}
                  facets={facets}
                  setFacets={(f: any) => {
                    flushSync(() => {
                      setFacets(f);
                      onTriggerSearch(searchedText || '', f);
                    });
                  }}
                  facetWrapper={FacetWrapper}
                />
              </div>
            )}
          </Grid.Column>
        )}

        <Grid.Column
          mobile={12}
          tablet={data.facets?.length ? 8 : 12}
          computer={data.facets?.length ? 9 : 12}
        >
          <FilterList
            {...props}
            isEditMode={isEditMode}
            setFacets={(f: any) => {
              flushSync(() => {
                setFacets(f);
                onTriggerSearch(searchedText || '', f);
              });
            }}
          />

          <div className="search-results-count-sort">
            <SearchDetails text={searchedText} total={totalItems} data={data} />
            <div className="sort-views-wrapper">
              {data.showSortOn && (
                <SortOn
                  querystring={querystring}
                  data={data}
                  isEditMode={isEditMode}
                  sortOn={sortOn}
                  sortOrder={sortOrder}
                  setSortOn={(sortOn: string) => {
                    flushSync(() => {
                      setSortOn(sortOn);
                      onTriggerSearch(searchedText || '', facets, sortOn);
                    });
                  }}
                  setSortOrder={(sortOrder: string) => {
                    flushSync(() => {
                      setSortOrder(sortOrder);
                      onTriggerSearch(
                        searchedText || '',
                        facets,
                        sortOn,
                        sortOrder,
                      );
                    });
                  }}
                />
              )}
              {data.availableViews && data.availableViews.length > 1 && (
                <ViewSwitcher {...props} />
              )}
            </div>
          </div>
          {children}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export { LeftColumnFacets };
