import { TagSocial } from '../../TagSocial';
import type { BlockViewProps, RelatedItem } from '@plone/types';

const View = ({
  data,
  isEditMode,
  content,
}: BlockViewProps & {
  isEditMode: boolean;
  className: string;
  data: {
    title: string;
    href: RelatedItem[];
    openLinkInNewTab: boolean;
  };
  content: {
    '@type': string;
    effective: string;
    modified: string;
    tags: string[];
  };
}) => {
  return (
    <TagSocial
      showSocials={true}
      tags={content?.tags ?? []}
      isEditMode={isEditMode}
      data={data}
      lastModified={content.modified}
      effectiveDate={content.effective}
      contenttype={content['@type']}
    />
  );
};

export default View;
