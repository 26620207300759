import React from 'react';
import cx from 'classnames';
import { isInternalURL } from '@plone/volto/helpers';
import { isValidUrl } from '@kitconcept/volto-iframe-block/components/schema';
import { IfConfirm } from '@kitconcept/volto-dsgvo-banner/components';

const IframeView = (props) => {
  const { className, data } = props;
  const width =
    data.width === 'center'
      ? '620px'
      : data.width === 'wide'
        ? '940px'
        : data.width === 'full' && '1440px';

  return (
    <div className={cx('block iframe align', data.align, className)}>
      <center>
        {data.src &&
          isValidUrl(data.src) &&
          (data.src.match('vlaanderen.be') ||
          data.src.match('vmm.be') ||
          data.src.match('irceline.be') ||
          isInternalURL(data.src) ? (
            <iframe
              src={data.src}
              title={data.title}
              width={width}
              height={data.height}
              allowfullscreen="true"
              allow="geolocation"
            />
          ) : (
            <IfConfirm module="thirdparty">
              <iframe
                src={data.src}
                title={data.title}
                width={width}
                height={data.height}
                allowfullscreen="true"
                allow="geolocation"
              />
            </IfConfirm>
          ))}
      </center>
    </div>
  );
};

export default IframeView;
