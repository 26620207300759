import { Image } from '@plone/volto/components';
import { BlockContainer } from '../BlockContainer';
import { Graph } from '../Graph';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  GraphSource: {
    id: 'GraphSource',
    defaultMessage: 'Source:',
  },
});

type Props = {
  content: {
    image: any;
    json_config: any;
    title: string;
    description: string;
    source: string;
    under_title: string;
  };
};

export const GraphTemplate = (props: Props) => {
  const intl = useIntl();

  // If there is a static image, we show it, otherwise we show a dynamic graph.
  const options = props.content.json_config;
  if (props.content.image || options) {
    return (
      <div id="page-document">
        <div className="blocks-group-wrapper transparent">
          <h1 className="documentFirstHeading">{props.content.title}</h1>
          {props.content.description && (
            <p className="documentDescription">{props.content.description}</p>
          )}
          <BlockContainer className="graph">
            {props.content.image && (
              <div className="image-wrapper">
                <Image
                  item={props.content}
                  imageField={'image'}
                  alt=""
                  loading="lazy"
                  responsive={true}
                />
              </div>
            )}
            {!props.content.image && options && <Graph options={options} />}
            {props.content.under_title && (
              <p className="graph-caption">{props.content.under_title}</p>
            )}
            {props.content.source && (
              <span className="graph-data-source">
                {intl.formatMessage(messages.GraphSource)}{' '}
                {props.content.source}
              </span>
            )}
          </BlockContainer>
        </div>
      </div>
    );
  }
};
