import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import { SocialsView } from './View';
import type { BlockEditProps } from '@plone/types';

const schema = {
  title: 'Socials',
  fieldsets: [{ id: 'default' }],
  properties: {},
  required: [],
};

export const SocialsEdit = ({
  selected,
  onChangeBlock,
  data,
  block,
  blocksErrors,
}: BlockEditProps) => {
  return (
    <>
      <SocialsView />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          title={schema.title}
          schema={schema}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => onChangeBlock(block, { ...data, [id]: value })}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};
