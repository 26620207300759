import LOGO_FB from '../../../assets/logo_fb.png';
import LOGO_IG from '../../../assets/logo_ig.svg';
import LOGO_X from '../../../assets/logo_x.svg';
import LOGO_IN from '../../../assets/logo_in.svg';
import LOGO_YT from '../../../assets/logo_yt.svg';

export const socials: [string, string, number][] = [
  [LOGO_FB, 'https://www.facebook.com/vlaamsemilieumaatschappij', 26],
  [LOGO_IG, 'https://instagram.com/vmmbe', 32],
  [LOGO_X, 'https://x.com/VMMbe', 24],
  [LOGO_IN, 'https://www.linkedin.com/company/vmm', 26],
  [LOGO_YT, 'https://www.youtube.com/user/VMMvideokanaal', 28],
];
