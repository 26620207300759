/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/heading-has-content */
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import { ComponentProps, LabelHTMLAttributes, ReactNode } from 'react';

type TypoSizes = `h${1 | 2 | 3 | 4 | 5}` | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type TypoStyleProps = {
  size?: TypoSizes;
  color?: 'primary' | 'secondary' | 'senary' | 'dark' | 'light';
  weight?: '300' | '400' | '500';
  className?: string;
  uppercase?: boolean;
  lineHeight?: 'normal' | 'broodtekst' | 'intro';
};

type TypoProps = {
  children: ReactNode;
} & TypoStyleProps;

const typoClassnames = ({
  size,
  color,
  weight,
  uppercase,
  className,
  lineHeight,
}: TypoStyleProps) =>
  cx([
    'typography',
    `typography--${size}`,
    weight && `typography--${weight}`,
    `typography--${color}`,
    uppercase && 'typography--uppercase',
    lineHeight && `typography--lineheight-${lineHeight}`,
    className,
  ]);

export const Label = (
  props: TypoProps & LabelHTMLAttributes<HTMLLabelElement>,
) => <label {...props} className={typoClassnames(props)} />;
Label.defaultProps = {
  color: 'dark',
  size: 'sm',
  lineHeight: 'normal',
};

export const Text = (props: TypoProps) => (
  <p {...props} className={typoClassnames(props)} />
);
Text.defaultProps = {
  color: 'dark',
  size: 'md',
  lineHeight: 'broodtekst',
};

export const Description = (props: TypoProps) => (
  <p {...props} className={typoClassnames(props)} />
);
Description.defaultProps = {
  color: 'dark',
  size: 'xl',
  weight: '300',
  lineHeight: 'intro',
};

export const H1 = (props: TypoProps) => (
  <h1 {...props} className={typoClassnames(props)} />
);
H1.defaultProps = {
  color: 'dark',
  size: 'h1',
  lineHeight: 'normal',
};

export const H2 = (props: TypoProps) => (
  <h2 {...props} className={typoClassnames(props)} />
);
H2.defaultProps = {
  color: 'dark',
  size: 'h2',
  lineHeight: 'normal',
};

export const H3 = (props: TypoProps) => (
  <h3 {...props} className={typoClassnames(props)} />
);
H3.defaultProps = {
  color: 'dark',
  size: 'h3',
  lineHeight: 'normal',
};

export const H4 = (props: TypoProps) => (
  <h4 {...props} className={typoClassnames(props)} />
);
H4.defaultProps = {
  color: 'dark',
  size: 'h4',
  lineHeight: 'normal',
};

export const H5 = (props: TypoProps) => (
  <h5 {...props} className={typoClassnames(props)} />
);
H5.defaultProps = {
  color: 'dark',
  size: 'h5',
  lineHeight: 'normal',
};

export const Link = (
  props: TypoProps & ComponentProps<typeof UniversalLink>,
) => {
  return <UniversalLink {...props} className={typoClassnames(props)} />;
};
Link.defaultProps = {
  color: 'dark',
  size: 'md',
  lineHeight: 'normal',
};
