import QuestionsBlock from '../../QuestionsBlock';
import type { BlockViewProps } from '@plone/types';

const View = ({
  data,
}: BlockViewProps & {
  data: {
    title: string;
    text: string;
    name: string;
    email: string;
    phone: string;
    openingHours: string;
  };
  isEditMode: boolean;
}) => {
  return (
    <QuestionsBlock
      title={data.title}
      text={data.text}
      name={data.name}
      email={data.email}
      phone={data.phone}
      openingHours={data.openingHours}
    />
  );
};

export default View;
