import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { MobileToolsFooter } from './MobileToolsFooter';
import MobileSearchWidget from '../SearchWidget/MobileSearchWidget';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
});

const MobileNavigation = (props) => {
  const [menuState, setMenuState] = React.useState({
    isMobileMenuOpen: false,
    secondaryMenuOpened: null,
    isSecondaryMobileMenuOpen: false,
    tertiaryMenuOpened: null,
    isTertiaryMobileMenuOpen: false,
  });
  const [openSubNavItems, setOpenSubNavItems] = useState([]);

  const { isMobileMenuOpen } = menuState;
  const intl = useIntl();
  const menus = React.useRef(null);
  const items = useSelector(
    (state) => state.navigation.items || [{ name: 'jjj' }],
  );
  const history = useHistory();

  const Footer = props.MobileToolsFooter || MobileToolsFooter;

  const toggleMobileMenu = useCallback(() => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('has-menu-open');

    setMenuState((prevState) => ({
      ...prevState,
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  }, []);

  const closeMenus = useCallback((e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    setMenuState(() => ({
      isSecondaryMobileMenuOpen: false,
      isTertiaryMobileMenuOpen: false,
      secondaryMenuOpened: null,
      tertiaryMenuOpened: null,
      isMobileMenuOpen: false,
    }));
  }, []);
  const toggleSelectedMenu = (index) => {
    if (!openSubNavItems.includes(index)) {
      setOpenSubNavItems([...openSubNavItems, index]);
    } else {
      let indexOfItem = openSubNavItems.indexOf(index);
      openSubNavItems.splice(indexOfItem, 1);
      setOpenSubNavItems([...openSubNavItems]);
    }
  };

  React.useEffect(() => {
    const closeMenuOnHistoryChange = history.listen(() => closeMenus({}));
    return () => {
      closeMenuOnHistoryChange();
    };
  }, [history, closeMenus]);

  return (
    <div className="mobile-nav mobile only tablet only" ref={menus}>
      <div className="hamburger-wrapper">
        <button
          className={cx('hamburger hamburger--collapse', {
            'is-active': isMobileMenuOpen,
          })}
          aria-expanded={isMobileMenuOpen ? true : false}
          aria-label={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          title={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu)
              : intl.formatMessage(messages.openMobileMenu)
          }
          type="button"
          onClick={toggleMobileMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>

      <CSSTransition
        in={isMobileMenuOpen}
        timeout={500}
        classNames="menu-drawer"
      >
        <div
          className="menu-drawer"
          style={{ backgroundColor: 'white', top: 'unset' }}
        >
          <MobileSearchWidget />

          <ul className="sections" style={{ marginLeft: 0, marginTop: '20px' }}>
            {items &&
              items.slice(0, 5).map((section, index) => (
                <li
                  key={section.url}
                  className={section.url === props.pathname ? 'current' : ''}
                  style={{ borderBottom: 'none' }}
                >
                  <div
                    role="button"
                    tabIndex={0}
                    style={{
                      width: '100%',
                    }}
                    onClick={() => toggleSelectedMenu(index)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        toggleSelectedMenu(index);
                      }
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      {section.items.length === 0 && (
                        <Link
                          style={{
                            color: '#0C3543',
                            cursor: 'pointer',
                            fontSize: '18px',
                            fontWeight: '500',
                            letterSpacing: '0.01em',
                            lineHeight: '23px',
                            marginBottom: '0',
                            marginLeft: '20px',
                            textAlign: 'left',
                          }}
                          to={section.url}
                        >
                          {section.nav_title || section.title}
                        </Link>
                      )}
                      {section.items.length > 0 && (
                        <p
                          style={
                            openSubNavItems.includes(index)
                              ? {
                                  borderBottom: '5px solid #1d9bf0',
                                  color: '#0C3543',
                                  cursor: 'pointer',
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  letterSpacing: '0.01em',
                                  lineHeight: '23px',
                                  marginBottom: '0',
                                  marginLeft: '20px',
                                  paddingBottom: '10px',
                                  textAlign: 'left',
                                }
                              : {
                                  color: '#0C3543',
                                  cursor: 'pointer',
                                  fontSize: '18px',
                                  fontWeight: '500',
                                  letterSpacing: '0.01em',
                                  lineHeight: '23px',
                                  marginBottom: '0',
                                  marginLeft: '20px',
                                  textAlign: 'left',
                                }
                          }
                        >
                          {section.nav_title || section.title}
                        </p>
                      )}
                      {section.items.length > 0 && (
                        <svg
                          style={
                            openSubNavItems.includes(index)
                              ? {
                                  rotate: '180deg',
                                  marginLeft: '20px',
                                  marginTop: '7px',
                                }
                              : { marginLeft: '20px', marginTop: '7px' }
                          }
                          width="11"
                          height="7"
                          viewBox="0 0 11 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1.34961L5.15 5.49961C5.19489 5.54744 5.24911 5.58556 5.30931 5.61162C5.3695 5.63768 5.4344 5.65113 5.5 5.65113C5.5656 5.65113 5.6305 5.63768 5.69069 5.61162C5.75089 5.58556 5.80511 5.54744 5.85 5.49961L10 1.34961"
                            stroke="#0C3543"
                            strokeWidth="1.7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>

                    {openSubNavItems.includes(index) &&
                      section.items.length > 0 && (
                        <ul
                          style={{
                            padding: '0 30px',
                            width: '100%',
                            backgroundColor: '#EDF4F6',
                          }}
                        >
                          {section.items.slice(0, 4).map((subnavitem) => {
                            return (
                              <li
                                key={subnavitem.url}
                                style={{
                                  borderBottom: '1px solid #d7e1e3',
                                  marginLeft: 0,
                                  width: '100%',
                                }}
                              >
                                <Link
                                  to={subnavitem.url}
                                  style={{
                                    width: '100%',
                                    fontSize: '18px',
                                    fontWeight: '500',
                                    lineHeight: '31px',
                                    letterSpacing: '0.02em',
                                    textAlign: 'left',
                                  }}
                                >
                                  {subnavitem.title}
                                  <svg
                                    width="20"
                                    height="25"
                                    viewBox="0 0 20 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      opacity="0.1"
                                      cx="10"
                                      cy="15"
                                      r="10"
                                      fill="#1D9BF0"
                                    />
                                    <path
                                      d="M8.52734 11L12.1273 14.6C12.182 14.6513 12.2256 14.7133 12.2554 14.7821C12.2851 14.8509 12.3005 14.925 12.3005 15C12.3005 15.075 12.2851 15.1491 12.2554 15.2179C12.2256 15.2867 12.182 15.3487 12.1273 15.4L8.52734 19"
                                      stroke="#1D9BF0"
                                      strokeWidth="1.71429"
                                      strokeLinecap="round"
                                      strokeJineJoin="round"
                                    />
                                  </svg>
                                </Link>
                              </li>
                            );
                          })}
                          <li
                            style={{
                              borderBottom: '1px solid #d7e1e3',
                              marginLeft: 0,
                              width: '100%',
                            }}
                          >
                            <Link
                              to={section.url}
                              style={{
                                alignItems: 'center',
                                backgroundColor: '#1D9BF0',
                                borderRadius: '80px',
                                color: 'white',
                                fontSize: '16px',
                                fontWeight: '500',
                                justifyContent: 'center',
                                letterSpacing: '0.01em',
                                lineHeight: '19.2px',
                                padding: '10px 40px',
                                textAlign: 'center',
                                textTransform: 'lowercase',
                                width: '100%',
                              }}
                            >
                              Bekijk alle {section.nav_title || section.title}
                              <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                style={{
                                  minWidth: '21px',
                                  marginLeft: '0.75em',
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  opacity="0.1"
                                  cx="10.5"
                                  cy="10"
                                  r="10"
                                  fill="white"
                                />
                                <path
                                  d="M9.02734 6L12.6273 9.6C12.682 9.6513 12.7256 9.71327 12.7554 9.78206C12.7851 9.85086 12.8005 9.92503 12.8005 10C12.8005 10.075 12.7851 10.1491 12.7554 10.2179C12.7256 10.2867 12.682 10.3487 12.6273 10.4L9.02734 14"
                                  stroke="white"
                                  strokeWidth="1.71429"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      )}
                  </div>
                </li>
              ))}
          </ul>
          <Footer />
        </div>
      </CSSTransition>
    </div>
  );
};

export default MobileNavigation;
