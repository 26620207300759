/**
 * Intranet Search Widget component.
 * @module components/SearchWidget
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  placeholder: {
    id: 'Search for People, E-Mail Address, Phone Number, ...',
    defaultMessage: 'Search for People, E-Mail Address, Phone Number, ...',
  },
});

/**
 * MobileSearchWidget component class.
 * @class MobileSearchWidget
 * @extends Component
 */
class MobileSearchWidget extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onChangeText = this.onChangeText.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      text: '',
    };
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText(event, { value }) {
    this.setState({
      text: value,
    });
  }

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit(event) {
    const path =
      this.props.pathname?.length > 0
        ? `&path=${encodeURIComponent(this.props.pathname)}`
        : '';
    this.props.history.push(
      `/search?SearchableText=${encodeURIComponent(this.state.text)}${path}`,
    );
    // reset input value
    this.setState({
      text: '',
    });
    event.preventDefault();
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { intl } = this.props;
    return (
      <Form action="/search" onSubmit={this.onSubmit}>
        <Form.Field
          className="searchbox"
          style={{
            borderBottom: '1px #D8E3E6 solid',
            borderLeft: 'none',
            margin: ' 0 30px',
          }}
        >
          <Input
            aria-label={intl.formatMessage(messages.search)}
            onChange={this.onChangeText}
            name="SearchableText"
            value={this.state.text}
            transparent
            autoComplete="off"
            placeholder={'Ik ben op zoek naar...'}
            title={intl.formatMessage(messages.search)}
            style={{
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '26px',
              textAlign: 'left',
            }}
          />

          <button aria-label={intl.formatMessage(messages.search)}>
            <svg
              style={{ margin: 'auto' }}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.6131 15.5802C12.4617 15.5802 15.5817 12.4603 15.5817 8.61164C15.5817 4.763 12.4617 1.64307 8.6131 1.64307C4.76447 1.64307 1.64453 4.763 1.64453 8.61164C1.64453 12.4603 4.76447 15.5802 8.6131 15.5802Z"
                stroke="#e7ebec"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3605 18.3576L13.5391 13.5361"
                stroke="#e7ebec"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </Form.Field>
      </Form>
    );
  }
}

export default compose(withRouter, injectIntl)(MobileSearchWidget);
