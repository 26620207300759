import PropTypes from 'prop-types';
import { ConditionalLink, Image } from '@plone/volto/components';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Tags } from '../../Tag/Tags';
import { H3, Text } from '../../Typography';
import { Button } from '../../Button';
import { ReadMoreLink } from '../../ReadMoreLink';
import downloadSvg from '../../../assets/download.svg';

type ListingItem = RelatedItem & { tags: string[]; end: string; start: string };
type Props = BlockViewProps & {
  '@type': string;
  item: ListingItem;
  blocks_layout: { items: any[] };
  styles?: { gridCutoff: 2 | 3 | 4 };
  isEditMode: boolean;
};

const PublicationItem = ({ item, isEditMode }: Props) => {
  return (
    <div className="listing-item listing-item--publication" key={item['@id']}>
      <ConditionalLink to={null} item={item} condition={!isEditMode}>
        <div className="listing-body">
          <Image
            item={item}
            imageField={item.image_field}
            alt=""
            loading="lazy"
            responsive={true}
          />
          <div className="listing-body__content">
            <H3>{item.title ? item.title : item['@id']}</H3>
            <Text>{item.description}</Text>
            <Tags tags={item.tags} />
            <div className="listing-body__ruler" />
            <div className="listing-body__actions">
              <Button
                className="download-publication"
                size="medium"
                color="tertiary"
                icon={downloadSvg}
              >
                Download .pdf
              </Button>
              <ReadMoreLink item={item} />
            </div>
          </div>
        </div>
      </ConditionalLink>
    </div>
  );
};
PublicationItem.propTypes = {
  item: PropTypes.any.isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default PublicationItem;
