import type { JSONSchema } from '@plone/types';
import GridBlockEdit from './Edit';
import GridBlockView from './View';
import {
  defaultBackgroundColors,
  defaultStylingSchema,
} from '../../../helpers/stylingSchemaEnhancer';
import { composeSchema } from '@plone/volto/helpers';
import { gridWrapSchemaEnhancer } from '../../../helpers/gridWrapSchemaEnhancer';

export const gridBlock = {
  edit: GridBlockEdit,
  view: GridBlockView,
  colors: defaultBackgroundColors,
  maxLength: 12,
  // dataAdapter: composeGridTeaserAdapter(
  //   gridTeaserCombination('extended'),
  //   gridTeaserCombination('spotlight'),
  //   gridTeaserCombination('cardTeaser'),
  // ),
  // variations: [
  //   {
  //     id: 'default',
  //     title: 'Standard',
  //     isDefault: true,
  //   },
  //   {
  //     id: 'cardTeaser',
  //     title: 'Basis',
  //   },
  //   {
  //     id: 'spotlight',
  //     title: 'In de kijker',
  //   },
  //   {
  //     id: 'extended',
  //     title: 'Uitgebreid',
  //   },
  // ],

  schemaEnhancer: composeSchema(
    defaultStylingSchema,
    gridWrapSchemaEnhancer,
    ({
      schema,
    }: {
      schema: JSONSchema & { properties: { [key: string]: object } };
    }) => {
      schema.properties.headlineLink = {
        title: 'Hoofding link',
        widget: 'object_browser',
        mode: 'link',
      };
      schema.properties.headlineLinkLabel = {
        title: 'Hoofding link label',
      };
      schema.properties.mobileCarousel = {
        title: 'Gebruik mobiel carousel',
        type: 'boolean',
      };

      schema.fieldsets[0].fields.push('headlineLink');
      schema.fieldsets[0].fields.push('headlineLinkLabel');
      schema.fieldsets[0].fields.push('mobileCarousel');
      return schema;
    },
  ),
};
