import { ReactNode } from 'react';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  glass?: boolean;
  dark?: boolean;
  border?: boolean;
  className?: string;
};

export const Card = (props: Props) => {
  const className = cx(
    {
      'card--glass': props.glass,
      'card--dark': props.dark,
      'card--border': props.border,
    },
    'card',
    props.className,
  );
  return <div className={className}>{props.children}</div>;
};
