import type { BlockViewProps } from '@plone/types';
import { IndicatorHeaderBlockData } from './Edit';
import { IndicatorHeader } from './DefaultIndicatorHeader';

export const IndicatorHeaderView = (
  props: BlockViewProps & {
    data: { variation: string } & IndicatorHeaderBlockData;
  },
) => {
  const variation = props.data.variation;
  const View =
    props.blocksConfig[props.data['@type']].variations?.find(
      (v) => v.id === variation,
    )?.template || IndicatorHeader;

  return <View {...props} />;
};
