import { ConditionalLink, Icon } from '@plone/volto/components';
import type { RelatedItem } from '@plone/types';
import { Text, H3 } from '../../Typography';
import cx from 'classnames';
import rightSvg from '@plone/volto/icons/right-key.svg';

type CardTeaserData = {
  title: string;
  description: string;
  href: undefined | RelatedItem[];
  styles: Partial<{ gridCutoff: 2 | 3 | 4; solid: boolean }>;
};

export const CardTeaser = ({
  data,
  isEditMode,
}: {
  isEditMode: boolean;
  data: CardTeaserData;
}) => {
  const item = data.href?.[0];
  return (
    <div
      className={cx('teaser', 'card-teaser', {
        'teaser--solid': data.styles?.solid,
      })}
    >
      <ConditionalLink
        className="teaser-item"
        condition={!isEditMode}
        item={item}
        to={undefined}
        openLinkInNewTab={false}
      >
        <div className="teaser__content">
          <H3 className="headline" size="h3" weight="500">
            {data?.title}
            <Icon name={rightSvg} size="24px" />
          </H3>
          <Text size="sm">{data.description}</Text>
        </div>
      </ConditionalLink>
    </div>
  );
};
