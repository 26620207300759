import PropTypes from 'prop-types';
import { Component } from '@plone/volto/components';
import type { BlockViewProps, RelatedItem } from '@plone/types';

type ListingItem = RelatedItem & { tags: string[] };
type Props = BlockViewProps & {
  '@type': string;
  items: ListingItem[];
  blocks_layout: { items: any[] };
  styles?: { gridCutoff: 2 | 3 | 4 };
  isEditMode: boolean;
  variant?: string;
};

const SummaryListing = ({ items, variant }: Props) => {
  return (
    <div
      className={`listing-items listing-items--${(variant || 'default').toLowerCase()}`}
    >
      {items.map((item) => {
        const dependency = variant ? variant : item ? item['@type'] : null;

        return (
          <Component
            key={item['@id']}
            componentName="ListingItem"
            dependencies={[dependency]}
            item={item}
          />
        );
      })}
    </div>
  );
};
SummaryListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export const listingVariant = (variant: string) => (props: Props) => (
  <SummaryListing {...props} variant={variant} />
);
export default SummaryListing;
