import type { BlockViewProps } from '@plone/types';
import { ConditionalLink } from '@plone/volto/components';
import '../SnelNaarBlock/SnelNaarBlock.scss';
type defaultTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  createdAt: string;
  href:
    | undefined
    | {
        '@id': string;
        hasPreviewImage: boolean;
        image_field: string;
        created: string;
      }[];
};

export const SnelNaarTeaser = ({
  data,
  isEditMode,
}: BlockViewProps & { data: defaultTeaserData; isEditMode: boolean }) => {
  const href = data.href?.[0];

  return (
    <div className="snel-naar-block">
      <ConditionalLink to={undefined} item={href} condition={!isEditMode}>
        <div className="flex">
          <p className="snbTitle">{data.title}</p>
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L6.15 6.15C6.19783 6.19489 6.23595 6.24911 6.26201 6.30931C6.28807 6.3695 6.30152 6.4344 6.30152 6.5C6.30152 6.5656 6.28807 6.6305 6.26201 6.69069C6.23595 6.75089 6.19783 6.80511 6.15 6.85L1 12"
              stroke="#234855"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <p className="snbText">{data.description}</p>
      </ConditionalLink>
    </div>
  );
};
