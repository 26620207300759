import View from './View';
import Edit from './Edit';

import icon from '@plone/volto/icons/right-key.svg';

export const tagSocialBlock = {
  id: 'tagSocialBlock',
  title: 'Tag Social',
  edit: Edit,
  view: View,
  icon: icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
