import { flattenToAppURL } from '@plone/volto/helpers';
import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { BlockContainer } from '../../BlockContainer';
import { Graph } from '../../Graph';
import { useEffect, useMemo, useState } from 'react';
import { GraphBlockData } from './Edit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  GraphSource: {
    id: 'GraphSource',
    defaultMessage: 'Source:',
  },
});

export const GraphBlockView = (
  props: BlockViewProps & {
    data: GraphBlockData;
  },
) => {
  const intl = useIntl();
  const target = props.data.target;

  const [fetchError, setFetchError] = useState(false);
  const [graphData, setGraphData] = useState(null);

  const isGraph = target && target['@type'] === 'Graph';
  useEffect(() => {
    if (!target || !isGraph) return;

    const url =
      '/++api++' +
      flattenToAppURL(target['@id'].replace('/@@download/excel', ''));
    fetch(url)
      .then((r) => r.json())
      .then((r) => {
        if (r.type === 'Unauthorized') {
          setGraphData(null);
          setFetchError(true);
        } else {
          setGraphData(r);
          setFetchError(false);
        }
      })
      .catch(() => {
        setGraphData(null);
        setFetchError(true);
      });
  }, [target, isGraph]);

  const error = useMemo(() => {
    if (!isGraph) return 'De geselecteerde content is geen grafiek.';
    if (fetchError)
      return 'Er ging iets mis bij het ophalen van de grafiekdata.';
  }, [fetchError, isGraph]);

  return target ? (
    <BlockContainer className="graph">
      {graphData?.image ? (
        <div className="staticFig">
          <div className="staticTitle">{target?.title}</div>
          <Image item={target} alt="" loading="lazy" responsive={true} />
        </div>
      ) : (
        graphData?.json_config && <Graph options={graphData.json_config} />
      )}

      {graphData?.under_title && (
        <p className="graph-caption">{graphData.under_title}</p>
      )}
      {graphData?.source && (
        <span className="graph-data-source">
          {intl.formatMessage(messages.GraphSource)} {graphData.source}
        </span>
      )}

      {error && <p className="error">{error}</p>}
    </BlockContainer>
  ) : (
    <BlockContainer className="graph" />
  );
};
