import { flattenToAppURL, flattenScales } from '@plone/volto/helpers';

export const imageSrc = (item: undefined | any) => {
  if (!item) return null;
  const isFromRealObject = !item.image_scales;
  const imageFieldWithDefault = item.image_field || 'image';

  const image: any = isFromRealObject
    ? flattenScales(item['@id'], item[imageFieldWithDefault])
    : flattenScales(item['@id'], item.image_scales[imageFieldWithDefault]?.[0]);

  if (!image) return null;

  const basePath = image.base_path || item['@id'];

  const src = `${flattenToAppURL(basePath)}/${image.download}`;

  return src;
};
