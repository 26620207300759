import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';
import { pickBy } from 'lodash';
import { SidebarPortal } from '@plone/volto/components';
import { BlocksForm } from '@plone/volto/components';
import PropTypes from 'prop-types';
import ContainerData from '@plone/volto/components/manage/Blocks/Container/Data';
import DefaultEditBlockWrapper from '@plone/volto/components/manage/Blocks/Container/EditBlockWrapper';
import SimpleContainerToolbar from '@plone/volto/components/manage/Blocks/Container/SimpleContainerToolbar';
import { v4 as uuid } from 'uuid';
import { blocksFormGenerator } from '@plone/volto/helpers';
import DefaultTemplateChooser from '@plone/volto/components/manage/TemplateChooser/TemplateChooser';
import config from '@plone/volto/registry';
import type {
  BlockConfigBase,
  BlockEditProps,
  RelatedItem,
  SlateBlock,
} from '@plone/types';
import { Link } from '../../Typography';

type BlockConfig = BlockConfigBase &
  SlateBlock & {
    allowedBlocks: string[];
    maxLength: number;
    templates: (block?: string) => any;
    containerToolbar: ComponentType<any>;
    templateChooser: ComponentType<any>;
    editBlockWrapper: ComponentType<any>;
  };
type Props = BlockEditProps & {
  direction: string;
  data: {
    blocks: any[];
    blocks_layout: { items: string[] };
    headline: string;
    headlineLink?: [RelatedItem];
    headlineLinkLabel?: string;
    placeholder?: string;
  };
  setSelectedBlock?: (block: string) => void;
  selectedBlock?: string;
};
const ContainerBlockEdit = (props: Props) => {
  const {
    block,
    data,
    direction = 'horizontal',
    onChangeBlock,
    onChangeField,
    pathname,
    selected,
    manage,
  } = props;

  const intl = useIntl();
  const blockType = data['@type'];
  const metadata = props.metadata || props.properties;
  const isInitialized = data?.blocks && data?.blocks_layout;
  const properties = isInitialized ? data : blocksFormGenerator(0, 0);
  const blockConfig = config.blocks.blocksConfig[blockType] as BlockConfig;
  const blocksConfig = blockConfig.blocksConfig || props.blocksConfig;
  const allowedBlocks = blockConfig.allowedBlocks;
  const maxLength = blockConfig.maxLength || 12;
  const templates = blockConfig.templates;
  const ContainerToolbar =
    blockConfig.containerToolbar || SimpleContainerToolbar;

  // Custom components from config or default ones
  const TemplateChooser = blockConfig.templateChooser || DefaultTemplateChooser;
  const EditBlockWrapper =
    blockConfig.editBlockWrapper || DefaultEditBlockWrapper;

  let [selectedBlock, setSelectedBlock] = useState(
    properties.blocks_layout.items[0],
  );
  if (props.setSelectedBlock) {
    ({ selectedBlock, setSelectedBlock } = props);
  }

  const blockState: { [key: string]: any } = {};

  const onAddNewBlock = () => {
    const newuuid = uuid();
    const type = allowedBlocks?.length === 1 ? allowedBlocks[0] : null;
    const blocks = data.blocks || properties.blocks;
    const blocks_layout = data.blocks_layout || properties.blocks_layout;
    const newFormData = {
      ...data,
      blocks: {
        ...blocks,
        [newuuid]: { '@type': type || 'empty' },
      },
      blocks_layout: {
        items: [...blocks_layout.items, newuuid],
      },
    };
    if (blocks_layout.items.length < maxLength) {
      onChangeBlock(block, {
        ...newFormData,
      });
    }
  };

  const onSelectTemplate = (templateIndex: number) => {
    const resultantTemplates =
      allowedBlocks?.length === 1 ? templates(allowedBlocks[0]) : templates();
    onChangeBlock(block, {
      ...data,
      ...resultantTemplates(intl)[templateIndex].blocksData,
    });
  };

  const allowedBlocksConfig = pickBy(blocksConfig, (_, key) =>
    allowedBlocks.includes(key),
  );

  const containerProps = {
    ...props,
    allowedBlocks,
    allowedBlocksConfig,
    blocksConfig,
    blockType,
    maxLength,
    metadata,
    onAddNewBlock,
    onSelectTemplate,
    selectedBlock,
    setSelectedBlock,
    templates,
  };

  return (
    <>
      <div className="block-header">
        <h2 className="headline">{data.headline}</h2>
        {data.headlineLink && (
          <Link
            openLinkInNewTab={false}
            href={null}
            item={data.headlineLink[0]}
            size="sm"
          >
            {data.headlineLinkLabel || data.headlineLink[0].title}
            {' >'}
          </Link>
        )}
      </div>

      {selected && <ContainerToolbar {...containerProps} />}

      {!isInitialized && templates && (
        <TemplateChooser
          templates={
            allowedBlocks?.length === 1
              ? templates(allowedBlocks[0])
              : templates()
          }
          onSelectTemplate={onSelectTemplate}
        />
      )}

      <BlocksForm
        metadata={metadata}
        properties={properties}
        direction={direction}
        manage={manage}
        selectedBlock={selected ? selectedBlock : null}
        blocksConfig={allowedBlocksConfig}
        title={data.placeholder}
        isContainer
        stopPropagation={selectedBlock}
        disableAddBlockOnEnterKey
        onSelectBlock={(id: string) => {
          setSelectedBlock(id);
        }}
        onChangeFormData={(newFormData: any) => {
          onChangeBlock(block, {
            ...data,
            ...newFormData,
          });
        }}
        onChangeField={(id: string, value: any) => {
          if (['blocks', 'blocks_layout'].includes(id)) {
            blockState[id] = value;
            onChangeBlock(block, {
              ...data,
              ...blockState,
            });
          } else {
            onChangeField(id, value);
          }
        }}
        pathname={pathname}
      >
        {({ draginfo }: any, editBlock: any, blockProps: any) => (
          <EditBlockWrapper draginfo={draginfo} blockProps={blockProps}>
            {editBlock}
          </EditBlockWrapper>
        )}
      </BlocksForm>
      <SidebarPortal selected={selected && !selectedBlock}>
        <ContainerData {...props}></ContainerData>
      </SidebarPortal>
    </>
  );
};

ContainerBlockEdit.propTypes = {
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  manage: PropTypes.bool.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default ContainerBlockEdit;
