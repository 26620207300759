import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import type { BlockViewProps } from '@plone/types';
import { getIndicators, Indicator as IndicatorType } from './indicators';
import { IndicatorHeaderBlockData } from './Edit';
import { ButtonLink } from '../../Button';
import { Card } from '../../Card';
import { Indicator } from '../../Indicator';
import { Warnings } from '../../Warnings';
import { imageSrc } from '../../../helpers/imageSrc';
import { useBreakpoint } from '../../../helpers/useMedia';

export const IndicatorHeader = ({
  data,
  small,
}: BlockViewProps & { data: IndicatorHeaderBlockData; small?: boolean }) => {
  const src = useMemo(() => imageSrc(data.image), [data.image]);

  const isBigScreen = useBreakpoint('lg');

  const [mappedIndicators, setIndicators] = useState<IndicatorType[]>([]);

  useEffect(() => {
    getIndicators()
      .then(
        (arr) =>
          arr.filter((indicator) => {
            return data.indicators?.includes(indicator.id);
          }) || [],
      )
      .then(setIndicators);
  }, [data.indicators, setIndicators]);

  return (
    <div
      className={cx('indicator-header', {
        'indicator-header--mobile': !isBigScreen,
        'indicator-header--small': small,
      })}
    >
      <div
        className="indicator-header__container"
        style={{
          backgroundImage: `radial-gradient(127.66% 76.12% at 0% 100%, #07232d 0%, rgba(7, 35, 45, 0) 100%), url(${src})`,
        }}
      >
        <div className="indicator-header__content">
          <div className="indicator-header__text">
            <h1>{data.title}</h1>
            <p>{data.subTitle}</p>
            {!small && data.button && data.link && (
              <ButtonLink
                glow
                textColor="white"
                color="primary"
                size="large"
                href={undefined}
                openLinkInNewTab={false}
                item={data.link}
              >
                {data.button}
              </ButtonLink>
            )}
          </div>
          {isBigScreen && (
            <div className="indicator-header__indicators">
              {!!mappedIndicators.length && (
                <Card glass>
                  {mappedIndicators.map(
                    (indicator, index) =>
                      indicator && (
                        <Indicator indicator={indicator} key={index} />
                      ),
                  )}
                </Card>
              )}
              {data.showWarnings && (
                <Card dark className="warnings">
                  <Warnings />
                </Card>
              )}
            </div>
          )}
        </div>
      </div>
      {!isBigScreen && (
        <div className="indicator-header__indicators">
          <div className="indicator-header__indicators-list">
            {mappedIndicators.map(
              (indicator, index, arr) =>
                indicator && (
                  <Indicator
                    key={index}
                    indicator={indicator}
                    vertical={arr.length !== 1}
                  />
                ),
            )}
          </div>
          {data.showWarnings && <Warnings />}
        </div>
      )}
    </div>
  );
};
