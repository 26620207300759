import { SocialsEdit } from './Edit';
import { SocialsView } from './View';
import icon from '@plone/volto/icons/right-key.svg';
import { defaultStylingSchema } from '../../../helpers/stylingSchemaEnhancer';

export const socials = {
  id: 'socials',
  title: 'Socials',
  edit: SocialsEdit,
  view: SocialsView,
  icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  colors: [{ label: 'Light', name: 'light' }],
  defaultBGColor: 'light',
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
  schemaEnhancer: (args: { schema: any; formData: any; intl: any }) => {
    const schema = defaultStylingSchema(args);
    return schema;
  },
};
