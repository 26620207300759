import DRAUGHT from '../../../assets/indicators/draught.svg';
import FLOOD from '../../../assets/indicators/flood.svg';
import AIR_QUALITY from '../../../assets/indicators/air_quality.svg';
import RAINFALL from '../../../assets/indicators/rainfall.svg';
import TIDE from '../../../assets/indicators/tide.svg';
//import SWIMMING from '../../../assets/indicators/swimming.svg';

export type IndicatorStatus =
  | 'Zeer goed'
  | 'Goed'
  | 'Geen risico'
  | 'Normaal'
  | 'Verhoogd risico'
  | 'Slecht';
export type Indicator = {
  id: string;
  name: string;
  icon: string | null;
  status: IndicatorStatus;
  futureStatus: IndicatorStatus | null;
  color: string;
  href: string;
};

const map: {
  [name: string]: {
    name: string;
    icon: string | null;
    href: string;
  };
} = {
  lucht: {
    name: 'Luchtkwaliteit',
    icon: AIR_QUALITY,
    href: '/feiten-cijfers/lucht/actuele-luchtkwaliteit',
  },
  overstroming: {
    name: 'Overstromingen',
    icon: FLOOD,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=overstroming/actueel',
  },
  neerslag: {
    name: 'Neerslag',
    icon: RAINFALL,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=neerslag/actueel',
  },
  droogte: {
    name: 'Droogte',
    icon: DRAUGHT,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=droogte/actueel',
  },
  getij: {
    name: 'Getij',
    icon: TIDE,
    href: 'https://waterinfo.vlaanderen.be/Themas#item=getij/actueel',
  },
  //  zwemwater: {
  //    name: 'Zwemwater',
  //    icon: SWIMMING,
  //    href: 'https://kwaliteitzwemwater.be',
  //  },
};
export const indicators = Object.keys(map);

export const getIndicators = async () => {
  // We have api-dev, api-tst and api, but when you are in the VMM VPN, only api can be reached.
  // Also, CORS headers may prevent the browser from accessing these urls.
  // And even if you use a browser extension to ignore this, the api servers may look at
  // the Referer header and give an empty answer if they don't like it.
  // Let's use the production domain by default.

  const hostname =
    typeof window !== 'undefined'
      ? window.location.hostname
      : process.env.RAZZLE_HOSTNAME;

  let HeaderIndicatorApiUrl = '';
  if (hostname?.indexOf('localhost') !== -1) {
    HeaderIndicatorApiUrl =
      '/cors-proxy/https://api.vmm.vlaanderen.be/dab/web/v1/indicator_macro';
  } else {
    HeaderIndicatorApiUrl = '/indicatorapi/dab/web/v1/indicator_macro';
  }

  return fetch(HeaderIndicatorApiUrl)
    .then((r) => r.json() as Promise<typeof apiResponse>)
    .then((r) =>
      r.value.reduce((list, v, _, arr) => {
        if (v.naam.indexOf('actueel') === -1) return list;

        const name = v.naam.replace('-actueel', '');
        const indicator: Indicator = {
          id: name,
          name: map[name]?.name || name,
          icon: map[name]?.icon,
          status: v.status_beschrijving as IndicatorStatus,
          futureStatus:
            (arr.find((v) => v.naam === `${name}-morgen`)
              ?.status_beschrijving as IndicatorStatus) || null,
          color: v.kleur,
          href: map[name]?.href,
        };

        list.push(indicator);
        return list;
      }, [] as Indicator[]),
    );
};

// export const indicators: Indicator[] = [
//   {
//     name: 'Lucht',
//     status: 'Goed',
//     color: '#00E789',
//     futureStatus: 'Zeer goed',
//     icon: AIR_QUALITY,
//     iconDark: AIR_QUALITY_DARK,
//     href: '#',
//   },
//   {
//     name: 'Overstroming',
//     status: 'Geen risico',
//     color: '#00E789',
//     futureStatus: 'Verhoogd risico',
//     icon: FLOOD,
//     iconDark: FLOOD_DARK,
//     href: '#',
//   },
//   {
//     name: 'Droogte',
//     status: 'Normaal',
//     color: '#20E5F1',
//     futureStatus: 'Normaal',
//     icon: DRAUGHT,
//     iconDark: DRAUGHT_DARK,
//     href: '#',
//   },
// ];
