// SemanticUI-free pre-@plone/components
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { Container } from '@plone/components';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

import { Icon } from '@plone/volto/components';
import separatorSVG from '../../../../assets/icons/breadcrumbs-separator.svg';
import homeSVG from '../../../../assets/icons/breadcrumbs-home.svg';

const MAX_CHARACTERS_BREADCRUMB_TEXT = 25;
const CHARACTER_ELIPSIS = '\u2026';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 */
export class BreadcrumbsComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  getEllipsedText(text) {
    if (text?.length > MAX_CHARACTERS_BREADCRUMB_TEXT)
      return text.slice(0, MAX_CHARACTERS_BREADCRUMB_TEXT) + CHARACTER_ELIPSIS;
    return text;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div
        role="navigation"
        aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
        className="breadcrumbs"
      >
        <Container layout>
          <div className="breadcrumb">
            <Link
              to={this.props.root || '/'}
              className="home"
              title={this.props.intl.formatMessage(messages.home)}
            >
              <Icon name={homeSVG} size="33px" color="transparent" />
            </Link>
            {this.props.items.map((item, index, items) => [
              <Icon
                key={`divider-${item.url}`}
                name={separatorSVG}
                size="10px"
                color="transparent"
                className="breadcrumb-separator"
              />,
              index < items.length - 1 ? (
                <Link
                  key={item.url}
                  to={item.url}
                  className="section"
                  title={item?.title}
                >
                  {this.getEllipsedText(item.title)}
                </Link>
              ) : (
                <div
                  className="section active"
                  key={item.url}
                  title={item?.title}
                >
                  {this.getEllipsedText(item.title)}
                </div>
              ),
            ])}
          </div>
        </Container>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);
