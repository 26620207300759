import { Button } from '../../Button';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import type { BlockViewProps, RelatedItem } from '@plone/types';

const messages = defineMessages({
  ButtonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
});

const justifyValues = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};
const View = ({
  data,
  isEditMode,
  className,
}: BlockViewProps & {
  isEditMode: boolean;
  className: string;
  data: {
    title: string;
    href: RelatedItem[];
    openLinkInNewTab: boolean;
    inneralign: keyof typeof justifyValues;
  };
}) => {
  const intl = useIntl();
  const hasLink = data?.href && !!data.href.length;

  let link = hasLink ? (
    data.href.length > 0 && (
      <ConditionalLink
        item={data.href[0]}
        to={null}
        condition={!isEditMode}
        openLinkInNewTab={data.openLinkInNewTab}
      >
        <Button size="large" textcolor="white" color="tertiary" icon>
          {data?.title || intl.formatMessage(messages.ButtonText)}
        </Button>
      </ConditionalLink>
    )
  ) : (
    <Button size="large" textcolor="white" color="tertiary" icon>
      {data?.title || intl.formatMessage(messages.ButtonText)}
    </Button>
  );

  return (
    <div className={cx('block __button', className)}>
      <div className="button container">
        <div
          className={cx(`align ${data?.inneralign}`)}
          style={{
            display: 'flex',
            justifyContent: justifyValues[data?.inneralign || 'left'],
          }}
        >
          {link}
        </div>
      </div>
    </div>
  );
};
export default View;
