import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { SocialsView } from '../../Blocks/Socials/View';

type NieuwsberichtViewProps = {
  content: {
    title: string;
    description: string;
    image: {
      download: string;
    };
    effective: string;
    '@type': string;
  };
};

const NieuwsberichtView = ({ content }: NieuwsberichtViewProps) => {
  return (
    <div>
      <div id="page-document" className="nieuwsbericht">
        <RenderBlocks content={content} />
      </div>
      <div className="blocks-group-wrapper light">
        <SocialsView />
      </div>
    </div>
  );
};

export default NieuwsberichtView;
