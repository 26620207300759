import type { BlockConfigBase, BlockExtension, JSONSchema } from '@plone/types';
import { addStyling } from '@plone/volto/helpers';
import registry from '@plone/registry';
import { Spotlight } from './Spotlight';
import { CardTeaser } from './Card';
import { Default } from './Default';
import { ComponentProps, ComponentType } from 'react';
import { Event } from './EventTeaser';
import { News } from './NewsTeaser';
import { DataTeaser } from './DataTeaser';
import { Publication } from './PublicationTeaser';
import { withData } from '../../../helpers/withData';
import { SnelNaarTeaser } from './SnelNaar';
import { StandaloneTeaser } from './StandaloneTeaser';
import { ProjectTeaser } from './ProjectTeaser';
import { CTATeaser } from './CTATeaser';
import { Minimal } from './MinimalTeaser';

export const gridTeaser = (config: BlockConfigBase) => {
  const newConfig = {
    ...config,
    schemaEnhancer: ({
      schema,
    }: {
      schema: JSONSchema & { properties: { [key: string]: object } };
    }) => {
      schema.properties.relatedLinks = {
        title: 'Related links',
        widget: 'object_browser',
        mode: 'link',
      };
      schema.fieldsets[0].fields.push('relatedLinks');
      (
        schema.properties.href as { selectedItemAttrs: string[] }
      ).selectedItemAttrs.push('subjects');
      (
        schema.properties.href as { selectedItemAttrs: string[] }
      ).selectedItemAttrs.push('created');

      return schema;
    },
  };

  /**
   *  Register components for automatic teaser selection
   */
  const teasers: { dependencies: string[]; component: ComponentType<any> }[] = [
    {
      component: Spotlight,
      dependencies: [],
    },
    {
      component: CardTeaser,
      dependencies: ['Document'],
    },
    {
      component: Spotlight,
      dependencies: [''],
    },
    {
      component: Minimal,
      dependencies: [''],
    },
    {
      component: ProjectTeaser,
      dependencies: ['Project'],
    },
    {
      component: News,
      dependencies: ['News Item'],
    },
    {
      component: Event,
      dependencies: ['Event'],
    },
    {
      component: Publication,
      dependencies: ['Publication'],
    },
    {
      component: SnelNaarTeaser,
      dependencies: ['Snel Naar'],
    },
    {
      component: withData<ComponentProps<typeof DataTeaser>>({
        styles: { solid: true },
      })(DataTeaser),
      dependencies: ['Cijfer'],
    },
    {
      component: withData<ComponentProps<typeof DataTeaser>>({
        styles: { solid: false },
      })(DataTeaser),
      dependencies: ['Dienst'],
    },
  ];

  teasers.forEach((item) =>
    registry.registerComponent({
      ...item,
      name: 'TeaserBody',
    }),
  );

  const cardTeaserEnhancer = ({
    schema,
    intl,
    formData,
  }: {
    schema: any;
    intl: any;
    formData: any;
  }) => {
    addStyling({ schema, intl, formData });
    schema.properties.styles.schema.properties.solid = {
      title: 'Gevuld',
      type: 'boolean',
    };
    schema.properties.styles.schema.fieldsets[0].fields.push('solid');
    return schema;
  };

  if (!newConfig.variations) newConfig.variations = [];

  /**
   * REGISTER VARIANTS
   */

  newConfig.variations = [
    {
      title: 'Default',
      id: 'default',
      template: Default,
      isDefault: true,
    },
  ];

  newConfig.variations.push({
    title: 'Basis',
    id: 'cardTeaser',
    template: CardTeaser,
    schemaEnhancer: cardTeaserEnhancer,
  } as BlockExtension);
  // newConfig.variations.push({
  //   title: 'Uitgebreid',
  //   id: 'extended',
  //   template: Extended,
  // } as BlockExtension);
  newConfig.variations.push({
    title: 'Spotlight',
    id: 'spotlight',
    template: Spotlight,
  } as BlockExtension);
  newConfig.variations.push({
    title: 'Minimal',
    id: 'minimal',
    template: Minimal,
  } as BlockExtension);
  newConfig.variations.push({
    title: 'Lees Meer Kaart',
    id: 'data',
    template: DataTeaser,
    schemaEnhancer: cardTeaserEnhancer,
  } as BlockExtension);
  // newConfig.variations.push({
  //   title: 'Stand alone',
  //   id: 'standalone',
  //   template: StandaloneTeaser,
  // } as BlockExtension);
  newConfig.variations.push({
    title: 'Call-to-action',
    id: 'cta',
    template: CTATeaser,
  } as BlockExtension);
  newConfig.variations.push({
    title: 'Snel Naar',
    id: 'snelnaar',
    template: SnelNaarTeaser,
  } as BlockExtension);

  return newConfig;
};

export const teaser = (config: BlockConfigBase) => {
  config.variations = [
    {
      title: 'Default',
      id: 'default',
      template: StandaloneTeaser,
      isDefault: true,
    },
  ];
  return config;
};
