import type { BlockConfigBase, JSONSchema } from '@plone/types';
import {
  defaultBackgroundColors,
  defaultStylingSchema,
} from '../../../../../../addons/volto-hoofdsite/src/helpers/stylingSchemaEnhancer';
import { gridWrapSchemaEnhancer } from '../../../helpers/gridWrapSchemaEnhancer';
import { ProjectListingTemplate } from './ProjectListingTemplate';
import { WhatsNewTemplate } from './WhatsNewTemplate';
import { addStyling, composeSchema } from '@plone/volto/helpers';
import { CardListingTemplate } from './CardListing';
import { TeaserListingTemplate } from './TeaserListingTemplate';
import CatalogusItem from './CatalogusItem';
import registry from '@plone/registry';
import EventsItem from './EventsItem';
import { ComponentType } from 'react';
import SummaryListing, { listingVariant } from './DefaultSummaryListing';
import PublicationItem from './PublicationItem';

export const listing = (
  config: BlockConfigBase & {
    colors: typeof defaultBackgroundColors;
    variations: {
      title: string;
      id: string;
      template: any;
      schemaEnhancer?: any;
    }[];
  },
) => {
  const schemaEnhancer = ({
    schema,
  }: {
    schema: JSONSchema & { properties: { [key: string]: any } };
  }) => {
    schema.properties.headlineLink = {
      title: 'Hoofding link',
      widget: 'object_browser',
      mode: 'link',
    };
    schema.properties.headlineLinkLabel = {
      title: 'Hoofding link',
    };

    const headlineIndex = schema.fieldsets[0].fields.findIndex(
      (f) => f === 'headline',
    );
    schema.fieldsets[0].fields.splice(
      headlineIndex + 1,
      0,
      'headlineLinkLabel',
    );
    schema.fieldsets[0].fields.splice(headlineIndex + 1, 0, 'headlineLink');

    schema.properties.querystring.default = {
      sort_on: 'effective',
      sort_order_boolean: 'descending',
    };

    return schema;
  };

  const updatedConfig = {
    ...config,
    colors: defaultBackgroundColors,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      gridWrapSchemaEnhancer,
      schemaEnhancer,
    ),
  };

  /**
   *  Register components for automatic teaser selection
   */
  const teasers: { dependencies: string[]; component: ComponentType<any> }[] = [
    {
      component: CatalogusItem,
      dependencies: [],
    },
    {
      component: CatalogusItem,
      dependencies: [
        'Document',
        'Project',
        'News Item',
        'Publication',
        'Snel Naar',
        'Cijfer',
        'Dienst',
      ],
    },
    {
      component: EventsItem,
      dependencies: ['Event'],
    },
    {
      component: PublicationItem,
      dependencies: ['Publication'],
    },
  ];

  teasers.forEach((item) => {
    if (item.dependencies.length)
      return item.dependencies.forEach((dep) =>
        registry.registerComponent({
          ...item,
          dependencies: dep,
          name: 'ListingItem',
        }),
      );
    else
      registry.registerComponent({
        ...item,
        name: 'ListingItem',
      });
  });

  updatedConfig.variations = [
    {
      id: 'summary-listing',
      title: 'Standaard (Samenvatting)',
      template: SummaryListing,
    },
    {
      title: 'Standaard (Grid)',
      id: 'teaser-listing',
      template: TeaserListingTemplate,
      isDefault: true,
    },
    {
      id: 'catalogus',
      title: 'Basis (Samenvatting)',
      template: listingVariant('Document'),
    },
    {
      title: 'Basis (Grid)',
      id: 'basic-card-listing',
      schemaEnhancer: ({
        schema,
        intl,
        formData,
      }: {
        schema: any;
        intl: any;
        formData: any;
      }) => {
        addStyling({ schema, intl, formData });
        schema.properties.styles.schema.properties.solid = {
          title: 'Gevuld',
          type: 'boolean',
        };
        schema.properties.styles.schema.fieldsets[0].fields.push('solid');
        return schema;
      },
      template: CardListingTemplate,
    },
    {
      id: 'events',
      title: 'Evenementen',
      template: listingVariant('Event'),
    },
    {
      id: 'publications',
      title: 'Publicaties',
      template: listingVariant('Publication'),
    },
    {
      title: 'VMM Pagina Samenvatting',
      id: 'vmm-article-listing',
      template: ProjectListingTemplate,
      isDefault: true,
    },
    {
      title: 'Wat is nieuw',
      id: 'whats-new',
      template: WhatsNewTemplate,
      schemaEnhancer: ({
        schema,
      }: {
        schema: JSONSchema & { properties: { [key: string]: object } };
      }) => {
        schema.properties.spotlightItem = {
          title: 'In de kijker',
          widget: 'object_browser',
          initialPath: '/nieuws',
          mode: 'link',
        };
        schema.properties.buttonLink = {
          title: 'Bekijk alle nieuws',
          widget: 'object_browser',
          mode: 'link',
        };
        const headlineIndex = schema.fieldsets[0].fields.findIndex(
          (f) => f === 'headlineLink',
        );
        const buttonLink = schema.fieldsets[0].fields.findIndex(
          (f) => f === 'buttonLink',
        );
        // Reminder: voegt velden toe aan fieldset
        schema.fieldsets[0].fields.splice(headlineIndex, 0, 'spotlightItem');
        schema.fieldsets[0].fields.splice(buttonLink, 0, 'buttonLink');
        return schema;
      },
    },
  ];

  return updatedConfig;
};
