import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import ContextNavigation from '@plone/volto/components/theme/Navigation/ContextNavigation';
import { Grid } from 'semantic-ui-react';
import { useBreakpoint } from '../../../helpers/useMedia';

type CijferViewProps = {
  content: {
    title: string;
    description: string;
    parent: string;
    location: Location;
  };
};

const CijferView = ({ content, location }: CijferViewProps) => {
  var parentPath, showParent;
  var pl = location?.pathname?.split('/')?.length - 1;
  const parent = content?.parent || null;
  if (parent && parent['@id']) {
    parentPath = new URL(parent['@id']).pathname || null;
    showParent = parentPath !== '/';
    pl = parentPath?.split('/')?.length - 1;
  }

  const isMobile = !useBreakpoint('md');

  return (
    <div id="page-document" className="cijfer">
      {isMobile ? (
        <RenderBlocks content={content} /> // Only show RenderBlocks on mobile
      ) : (
        <Grid>
          <Grid.Column className="three wide">
            <ContextNavigation
              pathname={!showParent ? location.pathname : parentPath}
              params={{
                name: (parent && parent?.title) || '',
                no_thumbs: true,
                currentFolderOnly: true,
                topLevel: pl - 1,
                bottomLevel: pl - 1,
              }}
            />
          </Grid.Column>
          <Grid.Column className="seven wide">
            <RenderBlocks content={content} />
          </Grid.Column>
          <Grid.Column className="two wide" />
        </Grid>
      )}
    </div>
  );
};

export default CijferView;
