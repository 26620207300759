import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { RelatedItem } from '@plone/types';
import WARNING_RED from '../../assets/warning-red.svg';
import WARNING_ORANGE from '../../assets/warning-orange.svg';
import WARNING_YELLOW from '../../assets/warning-yellow.svg';
import WARNING_WHITE from '../../assets/warning-white.svg';

import { UniversalLink } from '@plone/volto/components';

export type Warning = {
  icon: string;
  label: string;
  item: RelatedItem;
};

const icons = {
  red: WARNING_RED,
  orange: WARNING_ORANGE,
  yellow: WARNING_YELLOW,
  white: WARNING_WHITE,
};
export const getWarningIcon = (warninglevel: keyof typeof icons) =>
  icons[warninglevel];

const searchWarnings = async () => {
  return fetch(`//${window.location.host}/++api++/@querystring-search`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      metadata_fields: '_all',
      sort_on: 'effective',
      sort_order: 'descending',
      limit: '2',
      query: [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['Warning'],
        },
        {
          i: 'expires',
          o: 'plone.app.querystring.operation.date.largerThan',
          v: [new Date()],
        },
        {
          i: 'effective',
          o: 'plone.app.querystring.operation.date.lessThan',
          v: [new Date()],
        },
        {
          i: 'review_state',
          o: 'plone.app.querystring.operation.string.isNot',
          v: ['private'],
        },
      ],
    }),
  })
    .then((r) => r.json())
    .then(
      (r: { items: (RelatedItem & { warninglevel: keyof typeof icons })[] }) =>
        r.items.map((v) => ({
          icon: getWarningIcon(v.warninglevel),
          label: v.title,
          item: v,
        })),
    );
};

// Define translatable messages
const messages = defineMessages({
  warnings: {
    id: 'Warnings',
    defaultMessage: 'Warnings:', // Waarschuwingen:
  },
  warningIconAlt: {
    id: 'WarningIconAlt',
    defaultMessage: 'Warning icon', // Waarschuwingen icoon
  },
});

export const Warnings = () => {
  const [error, setFetchError] = useState(false);
  const [warnings, setWarnings] = useState<null | Warning[]>(null);

  useEffect(() => {
    searchWarnings()
      .then((r) => {
        setWarnings(r);
        setFetchError(false);
      })
      .catch((e) => {
        setWarnings(null);
        setFetchError(true);
      });
  }, []);

  const intl = useIntl();

  if (error) {
    return null;
  }

  if (!warnings?.length) {
    return null;
  }
  return (
    <div className="warnings">
      <h5>{intl.formatMessage(messages.warnings)}</h5>
      {warnings?.map((warning, index) => (
        <UniversalLink
          href={undefined}
          openLinkInNewTab={false}
          item={warning.item}
          key={index}
          className="warning-item"
        >
          <img
            src={warning.icon}
            alt={intl.formatMessage(messages.warningIconAlt)}
            className="warning-icon"
          />
          <span className="warning-label">{warning.label}</span>
        </UniversalLink>
      ))}
    </div>
  );
};
