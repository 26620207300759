import { useRef, useEffect } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';

/**
 * A facet that uses radio/checkboxes to provide an explicit list of values for
 * filtering
 */
const CheckboxFacet = (props) => {
  // using refs to avoid effect being re-executed
  const initialPropsRef = useRef(props);
  const onChangeRef = useRef(props.onChange);
  onChangeRef.current = props.onChange;

  /* This is currently not working. When you console.log choices.length and
  value.length you'll see them increase and match sometimes. 
  The problem is that choices contains all facets we search on in the base query
  example: feit subtype has 4. But if only 3 diffefent subtypes are available, choicse
  will have 4 items, of which 1 has a label undefined. 
  */

  useEffect(() => {
    const initialProps = initialPropsRef.current;
    const { choices, value, facet } = initialProps;
    const onChange = onChangeRef.current;
    if (choices.length === value.length) {
      onChange(facet.field.value, []);
    }
  }, [initialPropsRef, onChangeRef]);

  const { facet, choices, isMulti, onChange, value, isEditMode } = props;
  const facetValue = value;

  return (
    <div className="checkbox-facet">
      <Header as="h4">{facet.title ?? facet?.field?.label}</Header>
      <div className="entries">
        {choices.map(({ label, value }) => (
          <div className="entry" key={value}>
            <Checkbox
              id={value}
              disabled={isEditMode}
              label={label.split(' > ').splice(-1, 1)[0]}
              radio={!isMulti}
              checked={
                isMulti
                  ? !!facetValue?.find((f) => f.value === value)
                  : facetValue && facetValue.value === value
              }
              onChange={(e, { checked }) =>
                onChange(
                  facet.field.value,
                  isMulti
                    ? [
                        ...facetValue
                          .filter((f) => f.value !== value)
                          .map((f) => f.value),
                        ...(checked ? [value] : []),
                      ]
                    : checked
                      ? value
                      : null,
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CheckboxFacet.schemaEnhancer = selectFacetSchemaEnhancer;
CheckboxFacet.stateToValue = selectFacetStateToValue;
CheckboxFacet.valueToQuery = selectFacetValueToQuery;

export default CheckboxFacet;
