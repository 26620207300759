import { formatDate, long_date_format } from '@plone/volto/helpers/Utils/Date';
import { useSelector } from 'react-redux';

const LocalizedDayMonthYear = (my_date) => {
  // This is adapted from FormattedDate in Volto.
  // The month name is only thing we really need to localize,
  // so maybe this can be done in an easier way.  But it works.
  const language = useSelector((state) => state.intl.locale);
  const args = {
    date: my_date,
    long: true,
    includeTime: false,
    long_date_format,
    locale: language,
    formatToParts: true,
  };
  const parts = formatDate(args);
  let day,
    month,
    year = '';
  for (let index = 0; index < parts.length; index++) {
    let part = parts[index];
    if (part['type'] === 'day') {
      day = part['value'];
      continue;
    }
    if (part['type'] === 'month') {
      month = part['value'];
      continue;
    }
    if (part['type'] === 'year') {
      year = part['value'];
      continue;
    }
  }
  return { day, month, year };
};

const EventDate = ({ end, start }: { end: string; start: string }) => {
  const endDate = LocalizedDayMonthYear(end);
  const startDate = LocalizedDayMonthYear(start);
  if (endDate.month !== startDate.month || endDate.year !== startDate.year)
    return (
      <>
        <b>{startDate.day}</b> {startDate.month} {startDate.year}
        {' - '}
        <b>{endDate.day}</b> {endDate.month} {endDate.year}
      </>
    );

  if (endDate.day !== startDate.day)
    return (
      <>
        <b>
          {startDate.day} - {endDate.day}
        </b>{' '}
        {startDate.month} {startDate.year}
      </>
    );

  return (
    <>
      <b>{startDate.day}</b> {startDate.month} {startDate.year}
    </>
  );
};

export default EventDate;
