import { useCallback, useMemo, useSyncExternalStore } from 'react';

const breakpoints = {
  sm: 0,
  md: 768,
  lg: 940,
  xl: 1440,
};

export function useMediaQuery(query: string) {
  const subscribe = useCallback(
    (callback: () => unknown) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener('change', callback);
      return () => {
        matchMedia.removeEventListener('change', callback);
      };
    },
    [query],
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };

  const getServerSnapshot = () => {
    return false;
  };

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
}

export const useBreakpoint = (
  breakpoint: keyof typeof breakpoints | number,
  mode: 'min' | 'max' | 'clamp' = 'min',
) => {
  const mediaString = useMemo(() => {
    let widthQueries: (string | null)[] = [];
    if (typeof breakpoint === 'string') {
      const i = Object.keys(breakpoints).indexOf(breakpoint);
      const maxI = i + 1;

      widthQueries = [
        Object.values(breakpoints)[i],
        mode !== 'clamp' ? null : Object.values(breakpoints)[maxI],
      ]
        .map((width, i) => {
          if (!width) return null;
          if (i === 0) {
            const prefix = mode === 'clamp' ? 'min' : mode;
            return `(${prefix}-width : ${width}px)`;
          } else return `(max-width : ${width}px)`;
        })
        .filter(Boolean);
    } else {
      widthQueries = [`(min-width: ${breakpoint}px)`];
    }

    return ['only screen', ...widthQueries].join(' and ');
  }, [breakpoint, mode]);

  return useMediaQuery(mediaString);
};
