import { H2, H5 } from '../Typography';
import MailIcon from '../../assets/mail.svg';
import PhoneIcon from '../../assets/phone.svg';

type QuestionsBlockProps = {
  title: string;
  text: string;
  name: string;
  email: string;
  phone: string;
  openingHours: string;
};

const QuestionsBlock = ({
  title,
  text,
  name,
  email,
  phone,
  openingHours,
}: QuestionsBlockProps) => {
  return (
    <div className="questions">
      <div className="questions-left">
        {title.trim() && <H2>{title}</H2>}
        {text.trim() && <div className="questions-text">{text}</div>}
      </div>
      <div className="questions-right">
        <H5>{name}</H5>
        {email.trim() && (
          <div className="contact-link">
            <img src={MailIcon} alt="mail" />
            <p>
              <a href={`mailto:${email}`}>{email}</a>
            </p>
          </div>
        )}
        {phone.trim() && (
          <div className="contact-link">
            <img src={PhoneIcon} alt="phone" />
            <p>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
          </div>
        )}
        {phone.trim() && openingHours.trim() && (
          <span className="contact-opening-hours">{openingHours}</span>
        )}
      </div>
    </div>
  );
};

QuestionsBlock.defaultProps = {
  title: '',
  text: '',
  name: '',
  email: '',
  phone: '',
  openingHours: '',
};

export default QuestionsBlock;
