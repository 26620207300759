export const gridWrapSchemaEnhancer = ({
  schema,
  formData,
  intl,
}: {
  schema: any;
  formData: any;
  intl: any;
}) => {
  schema.properties.styles.schema.properties.gridCutoff = {
    title: 'Raster overloop',
    widget: 'select',
    choices: [
      [2, 2],
      [3, 3],
      [4, 4],
    ],
    default: 3,
  };
  schema.properties.styles.schema.fieldsets[0].fields.push('gridCutoff');
  return schema;
};
