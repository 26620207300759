import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { H3, Link, Text } from '../../Typography';
import type { RelatedItem } from '@plone/types/src/content/common';

type MinimalTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  href:
    | undefined
    | { '@id': string; hasPreviewImage: boolean; image_field: string }[];
  relatedLinks: RelatedItem[];
};
export const Minimal = ({
  data,
  isEditMode,
}: BlockViewProps & { isEditMode: boolean; data: MinimalTeaserData }) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const LinkComponent = isEditMode ? H3 : Link;
  return (
    <div className="teaser minimal-teaser">
      {href && (
        <LinkComponent
          openLinkInNewTab={false}
          href={href['@id']}
          size="h3"
          className="headline"
        >
          <div className="teaser-item default">
            {(href.hasPreviewImage || href.image_field || image) && (
              <div className="image-wrapper">
                <Image
                  item={image || href}
                  imageField={image ? image.image_field : href.image_field}
                  alt=""
                  loading="lazy"
                  responsive={true}
                />
              </div>
            )}
            <div className="teaser__content">
              {data.title}
              {!data.hide_description && (
                <Text size="sm">{data?.description}</Text>
              )}
            </div>
          </div>
        </LinkComponent>
      )}
    </div>
  );
};
