import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import View from './View';
import './SnelNaarBlock.scss';
import type { BlockEditProps } from '@plone/types';
import type { RelatedItem } from '@plone/types/src/content/common';

const schema = {
  title: 'Snel naar block',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'text', 'link'],
    },
  ],

  properties: {
    title: {
      id: 'title',
      title: 'Titel',
      placeholder: 'Type hier de titel',
    },
    text: {
      id: 'text',
      title: 'Tekst',
      placeholder: 'Type hier de tekst',
      widget: 'richText',
    },
    link: {
      id: 'url',
      title: 'Url',
      widget: 'object_browser',
      mode: 'link',
      return: 'single',
    },
  },
  required: ['title', 'text', 'url'],
};

export type SnelNaarBlockData = {
  '@type': string;
  title: null | [RelatedItem];
  link: RelatedItem;
};

const Edit = (props: BlockEditProps & { data: SnelNaarBlockData }) => {
  const { selected, onChangeBlock, block, data, blocksErrors } = props;
  return (
    <>
      <View {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          title={schema.title}
          schema={schema}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => onChangeBlock(block, { ...data, [id]: value })}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
          errors={blocksErrors}
        />
      </SidebarPortal>
    </>
  );
};
export default Edit;
