import type { BlockViewProps } from '@plone/types';
import { defineMessages, useIntl } from 'react-intl';
import { Text } from '../../Typography';
import { Card } from '../../Card';
import { Icon } from '@plone/volto/components';
import WARNING_RED from '../../../assets/icons/warning-red.svg';
import WARNING_ORANGE from '../../../assets/icons/warning-orange.svg';
import WARNING_YELLOW from '../../../assets/icons/warning-yellow.svg';
import WARNING_WHITE from '../../../assets/icons/warning-white.svg';

const icons = {
  red: WARNING_RED,
  orange: WARNING_ORANGE,
  yellow: WARNING_YELLOW,
  white: WARNING_WHITE,
};

export const getWarningIcon = (warninglevel: keyof typeof icons) =>
  icons[warninglevel];

// Define translatable messages
const messages = defineMessages({
  warnings: {
    id: 'WarningLevel',
    defaultMessage: 'Warninglevel:', // Waarschuwingenniveau:
  },
});

export const WarningLevelView = (
  props: BlockViewProps & {
    content: { warninglevel: any };
  },
) => {
  const intl = useIntl();

  return (
    <Card dark className="indicatorBlock">
      {props?.content?.warninglevel?.token ? (
        <Text color="light" className="indicatorText">
          <Icon
            name={icons[props.content?.warninglevel?.token]}
            size="25px"
            className="warningblock_icon"
          />
          {intl.formatMessage(messages.warnings)}:{' '}
          {props.content?.warninglevel?.title}
        </Text>
      ) : (
        <Text color="light" className="indicatorText">
          Bewaar a.u.b. voor nieuwe status
        </Text>
      )}
    </Card>
  );
};
