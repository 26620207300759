import { defaultStylingSchema } from '../../../../../helpers/stylingSchemaEnhancer';

export const ButtonStylingSchema = ({ schema, formData, intl }) => {
  defaultStylingSchema({ schema, formData, intl });

  schema.properties.inneralign.actions = ['left', 'center', 'right'];

  schema.fieldsets = schema.fieldsets.filter((v, i) => i !== 1);

  return schema;
};
